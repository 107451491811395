// Fetched from https://github.com/nomiclabs/hardhat/blob/ee4969a0a8f746f4775d4018326056d161066869/packages/hardhat-core/src/internal/hardhat-network/stack-traces/logger.ts#L47

export const ConsoleLogs = {
  // Legacy method signatures before this PR: https://github.com/NomicFoundation/hardhat/pull/2964
  1368866505: "()",
  1309416733: "(int)",
  4122065833: "(uint)",
  1093685164: "(string)",
  843419373: "(bool)",
  741264322: "(address)",
  199720790: "(bytes)",
  1847107880: "(bytes1)",
  3921027734: "(bytes2)",
  763578662: "(bytes3)",
  3764340945: "(bytes4)",
  2793701517: "(bytes5)",
  2927928721: "(bytes6)",
  1322614312: "(bytes7)",
  1334060334: "(bytes8)",
  2428341456: "(bytes9)",
  20780939: "(bytes10)",
  67127854: "(bytes11)",
  2258660029: "(bytes12)",
  2488442420: "(bytes13)",
  2456219775: "(bytes14)",
  3667227872: "(bytes15)",
  1717330180: "(bytes16)",
  866084666: "(bytes17)",
  3302112666: "(bytes18)",
  1584093747: "(bytes19)",
  1367925737: "(bytes20)",
  3923391840: "(bytes21)",
  3589990556: "(bytes22)",
  2879508237: "(bytes23)",
  4055063348: "(bytes24)",
  193248344: "(bytes25)",
  4172368369: "(bytes26)",
  976705501: "(bytes27)",
  3358255854: "(bytes28)",
  1265222613: "(bytes29)",
  3994207469: "(bytes30)",
  3263516050: "(bytes31)",
  666357637: "(bytes32)",
  1812949376: "(uint,uint)",
  262402885: "(uint,string)",
  510514412: "(uint,bool)",
  1491830284: "(uint,address)",
  2534451664: "(string,uint)",
  1264337527: "(string,string)",
  3283441205: "(string,bool)",
  832238387: "(string,address)",
  910912146: "(bool,uint)",
  2414527781: "(bool,string)",
  705760899: "(bool,bool)",
  2235320393: "(bool,address)",
  574869411: "(address,uint)",
  1973388987: "(address,string)",
  1974863315: "(address,bool)",
  3673216170: "(address,address)",
  3884059252: "(uint,uint,uint)",
  2104037094: "(uint,uint,string)",
  1733758967: "(uint,uint,bool)",
  3191032091: "(uint,uint,address)",
  1533929535: "(uint,string,uint)",
  1062716053: "(uint,string,string)",
  1185403086: "(uint,string,bool)",
  529592906: "(uint,string,address)",
  1515034914: "(uint,bool,uint)",
  2332955902: "(uint,bool,string)",
  3587091680: "(uint,bool,bool)",
  1112473535: "(uint,bool,address)",
  2286109610: "(uint,address,uint)",
  3464692859: "(uint,address,string)",
  2060456590: "(uint,address,bool)",
  2104993307: "(uint,address,address)",
  2526862595: "(string,uint,uint)",
  2750793529: "(string,uint,string)",
  4043501061: "(string,uint,bool)",
  3817119609: "(string,uint,address)",
  4083337817: "(string,string,uint)",
  753761519: "(string,string,string)",
  2967534005: "(string,string,bool)",
  2515337621: "(string,string,address)",
  689682896: "(string,bool,uint)",
  3801674877: "(string,bool,string)",
  2232122070: "(string,bool,bool)",
  2469116728: "(string,bool,address)",
  130552343: "(string,address,uint)",
  3773410639: "(string,address,string)",
  3374145236: "(string,address,bool)",
  4243355104: "(string,address,address)",
  995886048: "(bool,uint,uint)",
  3359211184: "(bool,uint,string)",
  464374251: "(bool,uint,bool)",
  3302110471: "(bool,uint,address)",
  3224906412: "(bool,string,uint)",
  2960557183: "(bool,string,string)",
  3686056519: "(bool,string,bool)",
  2509355347: "(bool,string,address)",
  2954061243: "(bool,bool,uint)",
  626391622: "(bool,bool,string)",
  1349555864: "(bool,bool,bool)",
  276362893: "(bool,bool,address)",
  3950005167: "(bool,address,uint)",
  3734671984: "(bool,address,string)",
  415876934: "(bool,address,bool)",
  3530962535: "(bool,address,address)",
  2273710942: "(address,uint,uint)",
  3136907337: "(address,uint,string)",
  3846889796: "(address,uint,bool)",
  2548867988: "(address,uint,address)",
  484110986: "(address,string,uint)",
  4218888805: "(address,string,string)",
  3473018801: "(address,string,bool)",
  4035396840: "(address,string,address)",
  742821141: "(address,bool,uint)",
  555898316: "(address,bool,string)",
  3951234194: "(address,bool,bool)",
  4044790253: "(address,bool,address)",
  1815506290: "(address,address,uint)",
  7426238: "(address,address,string)",
  4070990470: "(address,address,bool)",
  25986242: "(address,address,address)",
  1554033982: "(uint,uint,uint,uint)",
  2024634892: "(uint,uint,uint,string)",
  1683143115: "(uint,uint,uint,bool)",
  3766828905: "(uint,uint,uint,address)",
  949229117: "(uint,uint,string,uint)",
  2080582194: "(uint,uint,string,string)",
  2989403910: "(uint,uint,string,bool)",
  1127384482: "(uint,uint,string,address)",
  1818524812: "(uint,uint,bool,uint)",
  4024028142: "(uint,uint,bool,string)",
  2495495089: "(uint,uint,bool,bool)",
  3776410703: "(uint,uint,bool,address)",
  1628154048: "(uint,uint,address,uint)",
  3600994782: "(uint,uint,address,string)",
  2833785006: "(uint,uint,address,bool)",
  3398671136: "(uint,uint,address,address)",
  3221501959: "(uint,string,uint,uint)",
  2730232985: "(uint,string,uint,string)",
  2270850606: "(uint,string,uint,bool)",
  2877020669: "(uint,string,uint,address)",
  1995203422: "(uint,string,string,uint)",
  1474103825: "(uint,string,string,string)",
  310782872: "(uint,string,string,bool)",
  3432549024: "(uint,string,string,address)",
  2763295359: "(uint,string,bool,uint)",
  2370346144: "(uint,string,bool,string)",
  1371286465: "(uint,string,bool,bool)",
  2037328032: "(uint,string,bool,address)",
  2565338099: "(uint,string,address,uint)",
  4170733439: "(uint,string,address,string)",
  4181720887: "(uint,string,address,bool)",
  2141537675: "(uint,string,address,address)",
  1451396516: "(uint,bool,uint,uint)",
  3906845782: "(uint,bool,uint,string)",
  3534472445: "(uint,bool,uint,bool)",
  1329595790: "(uint,bool,uint,address)",
  2438978344: "(uint,bool,string,uint)",
  2754870525: "(uint,bool,string,string)",
  879671495: "(uint,bool,string,bool)",
  1231956916: "(uint,bool,string,address)",
  3173363033: "(uint,bool,bool,uint)",
  831186331: "(uint,bool,bool,string)",
  1315722005: "(uint,bool,bool,bool)",
  1392910941: "(uint,bool,bool,address)",
  1102442299: "(uint,bool,address,uint)",
  2721084958: "(uint,bool,address,string)",
  2449150530: "(uint,bool,address,bool)",
  2263728396: "(uint,bool,address,address)",
  3399106228: "(uint,address,uint,uint)",
  1054063912: "(uint,address,uint,string)",
  435581801: "(uint,address,uint,bool)",
  4256361684: "(uint,address,uint,address)",
  2697204968: "(uint,address,string,uint)",
  2373420580: "(uint,address,string,string)",
  581204390: "(uint,address,string,bool)",
  3420819197: "(uint,address,string,address)",
  2064181483: "(uint,address,bool,uint)",
  1676730946: "(uint,address,bool,string)",
  2116501773: "(uint,address,bool,bool)",
  3056677012: "(uint,address,bool,address)",
  2587672470: "(uint,address,address,uint)",
  2034490470: "(uint,address,address,string)",
  22350596: "(uint,address,address,bool)",
  1430734329: "(uint,address,address,address)",
  149837414: "(string,uint,uint,uint)",
  2773406909: "(string,uint,uint,string)",
  4147936829: "(string,uint,uint,bool)",
  3201771711: "(string,uint,uint,address)",
  2697245221: "(string,uint,string,uint)",
  1821956834: "(string,uint,string,string)",
  3919545039: "(string,uint,string,bool)",
  3144824297: "(string,uint,string,address)",
  1427009269: "(string,uint,bool,uint)",
  1993105508: "(string,uint,bool,string)",
  3816813520: "(string,uint,bool,bool)",
  3847527825: "(string,uint,bool,address)",
  1481210622: "(string,uint,address,uint)",
  844415720: "(string,uint,address,string)",
  285649143: "(string,uint,address,bool)",
  3939013249: "(string,uint,address,address)",
  3587119056: "(string,string,uint,uint)",
  2366909661: "(string,string,uint,string)",
  3864418506: "(string,string,uint,bool)",
  1565476480: "(string,string,uint,address)",
  2681211381: "(string,string,string,uint)",
  3731419658: "(string,string,string,string)",
  739726573: "(string,string,string,bool)",
  1834430276: "(string,string,string,address)",
  2256636538: "(string,string,bool,uint)",
  1585754346: "(string,string,bool,string)",
  1081628777: "(string,string,bool,bool)",
  3279013851: "(string,string,bool,address)",
  1250010474: "(string,string,address,uint)",
  3944480640: "(string,string,address,string)",
  1556958775: "(string,string,address,bool)",
  1134328815: "(string,string,address,address)",
  1572859960: "(string,bool,uint,uint)",
  1119461927: "(string,bool,uint,string)",
  1019590099: "(string,bool,uint,bool)",
  1909687565: "(string,bool,uint,address)",
  885731469: "(string,bool,string,uint)",
  2821114603: "(string,bool,string,string)",
  1066037277: "(string,bool,string,bool)",
  3764542249: "(string,bool,string,address)",
  2155164136: "(string,bool,bool,uint)",
  2636305885: "(string,bool,bool,string)",
  2304440517: "(string,bool,bool,bool)",
  1905304873: "(string,bool,bool,address)",
  685723286: "(string,bool,address,uint)",
  764294052: "(string,bool,address,string)",
  2508990662: "(string,bool,address,bool)",
  870964509: "(string,bool,address,address)",
  3668153533: "(string,address,uint,uint)",
  1280700980: "(string,address,uint,string)",
  1522647356: "(string,address,uint,bool)",
  2741431424: "(string,address,uint,address)",
  2405583849: "(string,address,string,uint)",
  609847026: "(string,address,string,string)",
  1595265676: "(string,address,string,bool)",
  2864486961: "(string,address,string,address)",
  3318856587: "(string,address,bool,uint)",
  72663161: "(string,address,bool,string)",
  2038975531: "(string,address,bool,bool)",
  573965245: "(string,address,bool,address)",
  1857524797: "(string,address,address,uint)",
  2148146279: "(string,address,address,string)",
  3047013728: "(string,address,address,bool)",
  3985582326: "(string,address,address,address)",
  853517604: "(bool,uint,uint,uint)",
  3657852616: "(bool,uint,uint,string)",
  2753397214: "(bool,uint,uint,bool)",
  4049711649: "(bool,uint,uint,address)",
  1098907931: "(bool,uint,string,uint)",
  3542771016: "(bool,uint,string,string)",
  2446522387: "(bool,uint,string,bool)",
  2781285673: "(bool,uint,string,address)",
  3554563475: "(bool,uint,bool,uint)",
  3067439572: "(bool,uint,bool,string)",
  2650928961: "(bool,uint,bool,bool)",
  1114097656: "(bool,uint,bool,address)",
  3399820138: "(bool,uint,address,uint)",
  403247937: "(bool,uint,address,string)",
  1705899016: "(bool,uint,address,bool)",
  2318373034: "(bool,uint,address,address)",
  2387273838: "(bool,string,uint,uint)",
  2007084013: "(bool,string,uint,string)",
  549177775: "(bool,string,uint,bool)",
  1529002296: "(bool,string,uint,address)",
  1574643090: "(bool,string,string,uint)",
  392356650: "(bool,string,string,string)",
  508266469: "(bool,string,string,bool)",
  2547225816: "(bool,string,string,address)",
  2372902053: "(bool,string,bool,uint)",
  1211958294: "(bool,string,bool,string)",
  3697185627: "(bool,string,bool,bool)",
  1401816747: "(bool,string,bool,address)",
  453743963: "(bool,string,address,uint)",
  316065672: "(bool,string,address,string)",
  1842623690: "(bool,string,address,bool)",
  724244700: "(bool,string,address,address)",
  1181212302: "(bool,bool,uint,uint)",
  1348569399: "(bool,bool,uint,string)",
  2874982852: "(bool,bool,uint,bool)",
  201299213: "(bool,bool,uint,address)",
  395003525: "(bool,bool,string,uint)",
  1830717265: "(bool,bool,string,string)",
  3092715066: "(bool,bool,string,bool)",
  4188875657: "(bool,bool,string,address)",
  3259532109: "(bool,bool,bool,uint)",
  719587540: "(bool,bool,bool,string)",
  992632032: "(bool,bool,bool,bool)",
  2352126746: "(bool,bool,bool,address)",
  1620281063: "(bool,bool,address,uint)",
  2695133539: "(bool,bool,address,string)",
  3231908568: "(bool,bool,address,bool)",
  4102557348: "(bool,bool,address,address)",
  2617143996: "(bool,address,uint,uint)",
  2691192883: "(bool,address,uint,string)",
  4002252402: "(bool,address,uint,bool)",
  1760647349: "(bool,address,uint,address)",
  194640930: "(bool,address,string,uint)",
  2805734838: "(bool,address,string,string)",
  3804222987: "(bool,address,string,bool)",
  1870422078: "(bool,address,string,address)",
  1287000017: "(bool,address,bool,uint)",
  1248250676: "(bool,address,bool,string)",
  1788626827: "(bool,address,bool,bool)",
  474063670: "(bool,address,bool,address)",
  1384430956: "(bool,address,address,uint)",
  3625099623: "(bool,address,address,string)",
  1180699616: "(bool,address,address,bool)",
  487903233: "(bool,address,address,address)",
  1024368100: "(address,uint,uint,uint)",
  2301889963: "(address,uint,uint,string)",
  3964381346: "(address,uint,uint,bool)",
  519451700: "(address,uint,uint,address)",
  4111650715: "(address,uint,string,uint)",
  2119616147: "(address,uint,string,string)",
  2751614737: "(address,uint,string,bool)",
  3698927108: "(address,uint,string,address)",
  1770996626: "(address,uint,bool,uint)",
  2391690869: "(address,uint,bool,string)",
  4272018778: "(address,uint,bool,bool)",
  602229106: "(address,uint,bool,address)",
  2782496616: "(address,uint,address,uint)",
  1567749022: "(address,uint,address,string)",
  4051804649: "(address,uint,address,bool)",
  3961816175: "(address,uint,address,address)",
  2764647008: "(address,string,uint,uint)",
  1561552329: "(address,string,uint,string)",
  2116357467: "(address,string,uint,bool)",
  3755464715: "(address,string,uint,address)",
  2706362425: "(address,string,string,uint)",
  1560462603: "(address,string,string,string)",
  900007711: "(address,string,string,bool)",
  2689478535: "(address,string,string,address)",
  3877655068: "(address,string,bool,uint)",
  3154862590: "(address,string,bool,string)",
  1595759775: "(address,string,bool,bool)",
  542667202: "(address,string,bool,address)",
  2350461865: "(address,string,address,uint)",
  4158874181: "(address,string,address,string)",
  233909110: "(address,string,address,bool)",
  221706784: "(address,string,address,address)",
  3255869470: "(address,bool,uint,uint)",
  2606272204: "(address,bool,uint,string)",
  2244855215: "(address,bool,uint,bool)",
  227337758: "(address,bool,uint,address)",
  2652011374: "(address,bool,string,uint)",
  1197235251: "(address,bool,string,string)",
  1353532957: "(address,bool,string,bool)",
  436029782: "(address,bool,string,address)",
  3484780374: "(address,bool,bool,uint)",
  3754205928: "(address,bool,bool,string)",
  3401856121: "(address,bool,bool,bool)",
  3476636805: "(address,bool,bool,address)",
  3698398930: "(address,bool,address,uint)",
  769095910: "(address,bool,address,string)",
  2801077007: "(address,bool,address,bool)",
  1711502813: "(address,bool,address,address)",
  1425929188: "(address,address,uint,uint)",
  2647731885: "(address,address,uint,string)",
  3270936812: "(address,address,uint,bool)",
  3603321462: "(address,address,uint,address)",
  69767936: "(address,address,string,uint)",
  566079269: "(address,address,string,string)",
  1863997774: "(address,address,string,bool)",
  2406706454: "(address,address,string,address)",
  2513854225: "(address,address,bool,uint)",
  2858762440: "(address,address,bool,string)",
  752096074: "(address,address,bool,bool)",
  2669396846: "(address,address,bool,address)",
  3982404743: "(address,address,address,uint)",
  4161329696: "(address,address,address,string)",
  238520724: "(address,address,address,bool)",
  1717301556: "(address,address,address,address)",
  4133908826: "(uint,uint)",
  3054400204: "(string,uint)",

  // Latest method signatures after updating uint to uint256 and int to int256

  760966329: "(int256)",
  4163653873: "(uint256)",
  1681903839: "(uint256, string)",
  480083635: "(uint256, bool)",
  1764191366: "(uint256, address)",
  965833939: "(bool, uint256)",
  2198464680: "(address, uint256)",
  3522001468: "(uint256, uint256, uint256)",
  1909476082: "(uint256, uint256, string)",
  1197922930: "(uint256, uint256, bool)",
  1553380145: "(uint256, uint256, address)",
  933920076: "(uint256, string, uint256)",
  2970968351: "(uint256, string, string)",
  1290643290: "(uint256, string, bool)",
  2063255897: "(uint256, string, address)",
  537493524: "(uint256, bool, uint256)",
  2239189025: "(uint256, bool, string)",
  544310864: "(uint256, bool, bool)",
  889741179: "(uint256, bool, address)",
  1520131797: "(uint256, address, uint256)",
  1674265081: "(uint256, address, string)",
  2607726658: "(uint256, address, bool)",
  3170737120: "(uint256, address, address)",
  3393701099: "(string, uint256, uint256)",
  1500569737: "(string, uint256, string)",
  3396809649: "(string, uint256, bool)",
  478069832: "(string, uint256, address)",
  1478619041: "(string, string, uint256)",
  3378075862: "(string, bool, uint256)",
  220641573: "(string, address, uint256)",
  923808615: "(bool, uint256, uint256)",
  3288086896: "(bool, uint256, string)",
  3906927529: "(bool, uint256, bool)",
  143587794: "(bool, uint256, address)",
  278130193: "(bool, string, uint256)",
  317855234: "(bool, bool, uint256)",
  1601936123: "(bool, address, uint256)",
  3063663350: "(address, uint256, uint256)",
  2717051050: "(address, uint256, string)",
  1736575400: "(address, uint256, bool)",
  2076235848: "(address, uint256, address)",
  1742565361: "(address, string, uint256)",
  2622462459: "(address, bool, uint256)",
  402547077: "(address, address, uint256)",
  423606272: "(uint256, uint256, uint256, uint256)",
  1506790371: "(uint256, uint256, uint256, string)",
  4202792367: "(uint256, uint256, uint256, address)",
  1570936811: "(uint256, uint256, string, uint256)",
  668512210: "(uint256, uint256, string, string)",
  2062986021: "(uint256, uint256, string, bool)",
  1121066423: "(uint256, uint256, string, address)",
  3950997458: "(uint256, uint256, bool, uint256)",
  2780101785: "(uint256, uint256, bool, string)",
  2869451494: "(uint256, uint256, bool, bool)",
  2592172675: "(uint256, uint256, bool, address)",
  2297881778: "(uint256, uint256, address, uint256)",
  1826504888: "(uint256, uint256, address, string)",
  365610102: "(uint256, uint256, address, bool)",
  1453707697: "(uint256, uint256, address, address)",
  2193775476: "(uint256, string, uint256, uint256)",
  3082360010: "(uint256, string, uint256, string)",
  1763348340: "(uint256, string, uint256, bool)",
  992115124: "(uint256, string, uint256, address)",
  2955463101: "(uint256, string, string, uint256)",
  564987523: "(uint256, string, string, string)",
  3014047421: "(uint256, string, string, bool)",
  3582182914: "(uint256, string, string, address)",
  3472922752: "(uint256, string, bool, uint256)",
  3537118157: "(uint256, string, bool, string)",
  3126025628: "(uint256, string, bool, bool)",
  2922300801: "(uint256, string, bool, address)",
  3906142605: "(uint256, string, address, uint256)",
  2621104033: "(uint256, string, address, string)",
  2428701270: "(uint256, string, address, bool)",
  1634266465: "(uint256, string, address, address)",
  3333212072: "(uint256, bool, uint256, uint256)",
  3724797812: "(uint256, bool, uint256, string)",
  2443193898: "(uint256, bool, uint256, bool)",
  2295029825: "(uint256, bool, uint256, address)",
  740099910: "(uint256, bool, string, uint256)",
  1757984957: "(uint256, bool, string, string)",
  3952250239: "(uint256, bool, string, bool)",
  4015165464: "(uint256, bool, string, address)",
  1952763427: "(uint256, bool, bool, uint256)",
  3722155361: "(uint256, bool, bool, string)",
  3069540257: "(uint256, bool, bool, bool)",
  1768164185: "(uint256, bool, bool, address)",
  125994997: "(uint256, bool, address, uint256)",
  2917159623: "(uint256, bool, address, string)",
  1162695845: "(uint256, bool, address, bool)",
  2716814523: "(uint256, bool, address, address)",
  211605953: "(uint256, address, uint256, uint256)",
  3719324961: "(uint256, address, uint256, string)",
  1601452668: "(uint256, address, uint256, bool)",
  364980149: "(uint256, address, uint256, address)",
  1182952285: "(uint256, address, string, uint256)",
  1041403043: "(uint256, address, string, string)",
  3425872647: "(uint256, address, string, bool)",
  2629472255: "(uint256, address, string, address)",
  1522374954: "(uint256, address, bool, uint256)",
  2432370346: "(uint256, address, bool, string)",
  3813741583: "(uint256, address, bool, bool)",
  4017276179: "(uint256, address, bool, address)",
  1936653238: "(uint256, address, address, uint256)",
  52195187: "(uint256, address, address, string)",
  153090805: "(uint256, address, address, bool)",
  612938772: "(uint256, address, address, address)",
  2812835923: "(string, uint256, uint256, uint256)",
  2236298390: "(string, uint256, uint256, string)",
  1982258066: "(string, uint256, uint256, bool)",
  3793609336: "(string, uint256, uint256, address)",
  3330189777: "(string, uint256, string, uint256)",
  1522028063: "(string, uint256, string, string)",
  2099530013: "(string, uint256, string, bool)",
  2084975268: "(string, uint256, string, address)",
  3827003247: "(string, uint256, bool, uint256)",
  2885106328: "(string, uint256, bool, string)",
  894187222: "(string, uint256, bool, bool)",
  3773389720: "(string, uint256, bool, address)",
  1325727174: "(string, uint256, address, uint256)",
  2684039059: "(string, uint256, address, string)",
  2182163010: "(string, uint256, address, bool)",
  1587722158: "(string, uint256, address, address)",
  4099767596: "(string, string, uint256, uint256)",
  1562023706: "(string, string, uint256, string)",
  3282609748: "(string, string, uint256, bool)",
  270792626: "(string, string, uint256, address)",
  2393878571: "(string, string, string, uint256)",
  3601791698: "(string, string, bool, uint256)",
  2093204999: "(string, string, address, uint256)",
  1689631591: "(string, bool, uint256, uint256)",
  1949134567: "(string, bool, uint256, string)",
  2331496330: "(string, bool, uint256, bool)",
  2472413631: "(string, bool, uint256, address)",
  620303461: "(string, bool, string, uint256)",
  2386524329: "(string, bool, bool, uint256)",
  1560853253: "(string, bool, address, uint256)",
  4176812830: "(string, address, uint256, uint256)",
  1514632754: "(string, address, uint256, string)",
  4232594928: "(string, address, uint256, bool)",
  1677429701: "(string, address, uint256, address)",
  2446397742: "(string, address, string, uint256)",
  1050642026: "(string, address, bool, uint256)",
  2398352281: "(string, address, address, uint256)",
  927708338: "(bool, uint256, uint256, uint256)",
  2389310301: "(bool, uint256, uint256, string)",
  3197649747: "(bool, uint256, uint256, bool)",
  14518201: "(bool, uint256, uint256, address)",
  1779538402: "(bool, uint256, string, uint256)",
  4122747465: "(bool, uint256, string, string)",
  3857124139: "(bool, uint256, string, bool)",
  4275904511: "(bool, uint256, string, address)",
  2437143473: "(bool, uint256, bool, string)",
  3468031191: "(bool, uint256, bool, bool)",
  2597139990: "(bool, uint256, bool, address)",
  355982471: "(bool, uint256, address, uint256)",
  464760986: "(bool, uint256, address, string)",
  3032683775: "(bool, uint256, address, bool)",
  653615272: "(bool, uint256, address, address)",
  679886795: "(bool, string, uint256, uint256)",
  450457062: "(bool, string, uint256, string)",
  1796103507: "(bool, string, uint256, bool)",
  362193358: "(bool, string, uint256, address)",
  2078327787: "(bool, string, string, uint256)",
  369533843: "(bool, string, bool, uint256)",
  196087467: "(bool, bool, uint256, uint256)",
  2111099104: "(bool, bool, uint256, string)",
  1637764366: "(bool, bool, uint256, bool)",
  1420274080: "(bool, bool, uint256, address)",
  3819555375: "(bool, bool, string, uint256)",
  1836074433: "(bool, bool, bool, uint256)",
  1276263767: "(bool, bool, address, uint256)",
  2079424929: "(bool, address, uint256, uint256)",
  1374724088: "(bool, address, uint256, string)",
  3590430492: "(bool, address, uint256, bool)",
  325780957: "(bool, address, uint256, address)",
  3256837319: "(bool, address, string, uint256)",
  126031106: "(bool, address, bool, uint256)",
  208064958: "(bool, address, address, uint256)",
  888202806: "(address, uint256, uint256, uint256)",
  1244184599: "(address, uint256, uint256, string)",
  1727118439: "(address, uint256, uint256, bool)",
  551786573: "(address, uint256, uint256, address)",
  3204577425: "(address, uint256, string, uint256)",
  2292761606: "(address, uint256, string, string)",
  3474460764: "(address, uint256, string, bool)",
  1547898183: "(address, uint256, string, address)",
  586594713: "(address, uint256, bool, uint256)",
  3316483577: "(address, uint256, bool, string)",
  1005970743: "(address, uint256, bool, bool)",
  2736520652: "(address, uint256, bool, address)",
  269444366: "(address, uint256, address, uint256)",
  497649386: "(address, uint256, address, string)",
  2713504179: "(address, uint256, address, bool)",
  1200430178: "(address, uint256, address, address)",
  499704248: "(address, string, uint256, uint256)",
  1149776040: "(address, string, uint256, string)",
  251125840: "(address, string, uint256, bool)",
  1662531192: "(address, string, uint256, address)",
  362776871: "(address, string, string, uint256)",
  1365129398: "(address, string, bool, uint256)",
  1166009295: "(address, string, address, uint256)",
  946861556: "(address, bool, uint256, uint256)",
  178704301: "(address, bool, uint256, string)",
  3294903840: "(address, bool, uint256, bool)",
  3438776481: "(address, bool, uint256, address)",
  2162598411: "(address, bool, string, uint256)",
  2353946086: "(address, bool, bool, uint256)",
  2807847390: "(address, bool, address, uint256)",
  3193255041: "(address, address, uint256, uint256)",
  4256496016: "(address, address, uint256, string)",
  2604815586: "(address, address, uint256, bool)",
  2376523509: "(address, address, uint256, address)",
  4011651047: "(address, address, string, uint256)",
  963766156: "(address, address, bool, uint256)",
  2485456247: "(address, address, address, uint256)",
};
