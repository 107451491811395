import React from "react"; // eslint-disable-line

import "./modal-dialog-custom.css";

/* eslint-disable-next-line */
export interface ModalDialogCustomProps {}

export const ModalDialogCustom = (props: ModalDialogCustomProps) => {
  return (
    <div>
      <h1>Welcome to modal-dialog-custom!</h1>
    </div>
  );
};

export default ModalDialogCustom;
